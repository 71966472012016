import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../../../image/Logo.png";

import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../LoaderPageSection/Loading";
import RegisterPageSection from "../RegisterPageSection/Content";
import { StakeAbi, StakeAddress } from "../Abi/Content";
import { ethers } from "ethers";
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [auth, setAuth] = useAuth();

  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 10) +
        "...." +
        address.substr(address.length - 10, address.length)
      );
    }
    return address;
  };
  const handleIsConnectApi = async (address) => {
    try {
      const resp = await axios.post(`/api/user/userLogin`, {
        accountAddress: address,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === false) {
          const message = resp?.data?.message;
          if (message === "No User is registered with this Account address") {
            toast.error("Please Register", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
        if (resp?.data?.status === true) {
          const message = resp?.data?.message;
          const { accountAddress, sponserAddress, dateOfRegistration, userId } =
            resp?.data?.data;
          if (message === "Login successfully") {
            const isAdmin = [
              "0x2aa5f670f789c7630ffddc80c6efecb2f06128ff",
              //  "0x65c0cb0e58d0a45d294bc0d1c37ee8c714e1372d"
              "0xbe99181bcab37bc57d3d2c7156bb7e1b72aafba2",
            ];
            var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
            setAuth({
              ...auth,
              user: {
                userId,
                address: accountAddress,
                referralAddress: sponserAddress,
                role: role,
                token: resp?.data?.token,
                dateOfRegistration,
              },
            });
            localStorage.setItem(
              "auth",
              JSON.stringify({
                user: {
                  userId,
                  address: accountAddress,
                  referralAddress: sponserAddress,
                  role: role,
                  token: resp?.data?.token,
                  dateOfRegistration,
                },
              })
            );
            localStorage.setItem("myToggled", 0);
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${resp?.data?.token}`;
            let data = localStorage.getItem("auth");
            data = JSON.parse(data);
            const admin = data?.user?.role;
            if (admin === 1) {
              navigate("/admin-stakeone");
            } else {
              navigate("/stakeone");
            }
          }
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const message = [
        "Welcome to the Decentrawood Lock!",
        "Please sign this message to verify your identity.",
        "Please sign in!",
      ].join("\n\n");
      await signer.signMessage(message);
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      setAddress(address);
      await handleIsConnectApi(address);
    } catch (error) {
      console.log("wallet loagin Error", error);
    }
    setLoading(false);
  };

  const handleIsDisconnect = () => {
    setAuth({
      ...auth,
      user: null,
    });
    localStorage.removeItem("auth");
    navigate("/");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        {loading && <Loading loading={loading} />}
        <div className="container">
          <Link className="navbar-brand">
            <img src={Logo} alt="logo" />
            <span className="logo-txt mx-1">Decentrawood</span>
          </Link>
          <button
            className="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              fill="#fff"
              class="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            {auth?.user && (
              <>
                <div>
                  <span className="user-icon">
                    <i class="bi bi-person-circle text-white mx-1"></i>
                  </span>
                  {auth?.user?.role === 0 ? (
                    <span className="text-white">User</span>
                  ) : (
                    <span className="text-white">Admin</span>
                  )}
                </div>
                <button className="btn walled-ad mx-2">
                  <span className="user-icon">
                    <i class="bi bi-wallet2 "></i>
                  </span>
                  {start_and_end(auth?.user?.address)}
                </button>
              </>
            )}

            {!auth?.user ? (
              <form class="form-box my-3" onSubmit={handleSubmit}>
                <button className="btn connect-btn text-white" type="submit">
                  Connect
                </button>
              </form>
            ) : (
              <form class="form-box my-3" onSubmit={handleIsDisconnect}>
                <button className="btn connect-btn" type="submit">
                  Disconnect
                </button>
              </form>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
