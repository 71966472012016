import React, { useEffect, useState } from "react";
import Layout from "../Layout/Content";
import { useAuth } from "../../context/auth";
import { ethers } from "ethers";
import { DeodAbi, DeodAddress, StakeAbi, StakeAddress } from "../Abi/Content";
import Loading from "../LoaderPageSection/Loading";
import { toast } from "react-toastify";
import { BigNumber } from "bignumber.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../../App.css";
import { getAllByPlaceholderText, logDOM } from "@testing-library/react";

const Content = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [refferal, setRefferal] = useState("");
  const [deodPrice, setDeodPrice] = useState(0);
  const [address, setAddress] = useState("");
  const [timeoutPeriod, setTimeoutPeriod] = useState("365");
  const [percentage, setPercentage] = useState("");
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const Paraswap =
    "https://api.paraswap.io/prices/?srcToken=0xc2132D05D31c914a87C6611C10748AEb04B58e8F&destToken=0xe77abb1e75d2913b2076dd16049992ffeaca5235&amount=1000000&srcDecimals=6&destDecimals=18&side=SELL&excludeDirectContractMethods=false&network=137&otherExchangePrices=true&partner=paraswap.io&userAddress=0x0000000000000000000000000000000000000000";
  const start_and_end = (address) => {
    if (address !== undefined) {
      return (
        address.substr(0, 15) +
        "...." +
        address.substr(address.length - 15, address.length)
      );
    }
    return address;
  };

  const hadleGetData = async () => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }

      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);

      let getPercentageDays = await StakeContract.getBonusFromDays(
        timeoutPeriod
      );
      getPercentageDays = parseFloat(getPercentageDays) / 10;
      console.log("getPercentageDays", getPercentageDays);
      setPercentage(getPercentageDays);
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleStaking = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (amount < 1000) {
      toast.error("Amount should be greater then 1000", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      try {
        let provider;
        if (window?.ethereum) {
          provider = window?.ethereum;
        } else if (window?.web3) {
          provider = window?.web3?.currentProvider;
        } else {
          window.alert("No ethereum browser !checkout metamask");
        }
        if (provider) {
          if (provider !== window?.ethereum) {
            console.error(
              "Not window.ethereum.Do you have multiple wallets installed"
            );
          }
          await provider.request({
            method: "eth_requestAccounts",
          });
        }
        const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await oldProvider.getNetwork();
        // console.log( network)
        // if (network.chainId !== networkId) {
        //   await switchNetworks();
        // }
        const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
        const signer = newProvider.getSigner();
        const currentaccount = await signer.getAddress();
        const address = currentaccount.toLowerCase();
        const storeData = auth?.user?.address;
        if (address === storeData) {
          const DeodContract = new ethers.Contract(
            DeodAddress,
            DeodAbi,
            signer
          );
          const StackContract = new ethers.Contract(
            StakeAddress,
            StakeAbi,
            signer
          );
          let x;
          x = amount * 10 ** 18;
          let z = new BigNumber(x);
          let a = z.toFixed();

          let yAmount;
          yAmount = (+amount + 1) * 10 ** 18;
          console.log("yAmount", typeof yAmount);
          let zAmount = new BigNumber(yAmount);
          let aproveAmount = zAmount.toFixed();

          const txn = await DeodContract.approve(StakeAddress, aproveAmount);
          const receipttxn = await txn.wait();
          if (receipttxn.status !== 1) {
            alert("error message");
          } else {
            const refferalAddress = auth?.user?.referralAddress;
            const txn1 = await StackContract.stakeTokens(
              a,
              refferalAddress,
              +timeoutPeriod
            );
            const receipttxn1 = await txn1.wait();
            console.log("receipttxn1", receipttxn1);
            const token = auth?.user?.token;
            const headers = {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // You can adjust this as needed
            };
            const bodyData = {
              accountAddress: address,
              sponserAddress: refferalAddress,
              depositAmount: amount,
              percentage: percentage,
              day: timeoutPeriod,
              transactionHash: receipttxn1.transactionHash,
            };
            try {
              const resp = await axios.post(`/api/user/stakeV1`, bodyData, {
                headers,
              });
              if (resp.status === 200) {
                if (resp?.data?.status === true) {
                  toast.success(resp?.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  navigate("/stakeone");
                } else {
                  toast.error("Error", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              } else {
                toast.error("Error", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } catch (error) {
              if (error.response.status === 400) {
                var err_400 = error?.response?.data?.message;
                toast.error(err_400);
              } else if (error.response.status === 401) {
                const err_401 = error?.response?.data?.message;
                toast.error(err_401);
              } else if (error.response.status === 404) {
                const err_404 = error?.response?.data?.message;
                toast.error(err_404);
              } else if (error.response.status === 500) {
                const err_500 = error?.response?.data?.message;
                toast.error(err_500);
              } else {
                console.log("Error in Register", error);
                toast.error("something went wrong");
              }
            }
          }
          setLoading(false);
        } else {
          toast.error(`connect correct wallet`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.log("loadBlockchainData", error);
        toast.error(`${JSON.stringify(error.reason)}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (
      auth?.user?.address &&
      auth?.user?.referralAddress &&
      auth?.user?.token
    ) {
      setRefferal(auth?.user?.referralAddress);
      setAddress(auth?.user?.address);
    }
    setLoading(false);
  }, [auth]);

  useEffect(() => {
    const fetchData = async () => {
      if (timeoutPeriod !== "") {
        await hadleGetData();
      }
    };
    fetchData();
  }, [timeoutPeriod]);

  useEffect(() => {
    const getData = async () => {
      await hadleGetData();
    };
    getData();
  }, [timeoutPeriod]);

  useEffect(() => {
    async function handleGetParaswapDeodprice() {
      try {
        const getParaswapData = await axios.get(Paraswap);
        if (getParaswapData.status === 200) {
          let finalDeodPrice = getParaswapData?.data?.priceRoute?.destAmount;
          finalDeodPrice = (finalDeodPrice / 10 ** 18).toFixed(2);
          setDeodPrice(finalDeodPrice);

          console.log("finaldeod", finalDeodPrice);
        }
      } catch (error) {
        console.log("error in deod price paraswap api", error);
      }
    }
    handleGetParaswapDeodprice();
  }, []);
  return (
    <Layout title={"Landing page"}>
      {loading && <Loading loading={loading} />}
      <div className="home-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="allotment-box">
                <form onSubmit={handleStaking}>
                  {/* <form> */}
                  <div className="row">
                    <div className="col-12">
                      <h4
                        className="text-light float-end cus-cancelbtn"
                        onClick={() => navigate("/stakeone")}
                      >
                        X
                      </h4>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Wallet Address
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={start_and_end(address)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Refferal Address
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={start_and_end(refferal)}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="mb-3">
                          <label className="form-label cus-label">
                            Time Period
                          </label>
                          <select
                            class="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setTimeoutPeriod(e.target.value)}
                          >
                            <option value="365">1 Year</option>
                            <option value="730">2 Year</option>
                            <option value="1095">3 Year</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label className="form-label cus-label">
                            Percentage
                          </label>
                          <input
                            type="number"
                            className="form-control cus-control-form"
                            value={percentage}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">Amount</label>
                        <input
                          type="number"
                          className="form-control cus-control-form"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                        <span className="my-3 mx-2 text-warning font-weight-bold ">
                          Amount should be greater then 1000
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          1 USDT = {deodPrice} Deod
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn connect-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
