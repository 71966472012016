import { Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import AdminDashbordPage from "./components/pages/AdminDashbordPage";
import UserDashbordPage from "./components/pages/UserDashbordPage";
import StackTokenPage from "./components/pages/StackTokenPage";
import RegisterPage from "./components/pages/RegisterPage";
import StakeOne from "./components/sections/UserPageSection/UserstakeOne";
import StakeTwo from "./components/sections/UserPageSection/UserstakeTwo";
import StakeThree from "./components/sections/UserPageSection/UserstakeThree";
import StakeFour from "./components/sections/UserPageSection/UserstakeFour";
import AdminStakeOne from "./components/sections/AdminPageSection/AdminstackOne";
import AdminStakeTwo from "./components/sections/AdminPageSection/AdminstackTwo";
import AdminStakeThree from "./components/sections/AdminPageSection/AdminstackThree";
import AdminstackFour from "./components/sections/AdminPageSection/AdminstackFour";
import StackTokenv2Page from "./components/pages/StackTokenv2Page";
import StackTokenv3Page from "./components/pages/StackTokenv3Page";
import StackTokenv4Page from "./components/pages/StackTokenv4Page";
import StakingPage from "./components/pages/StakingPage";
import AdminStaking from "./components/sections/AdminPageSection/AdminStaking";
import AdminstackPrice from "./components/sections/AdminPageSection/AdminstackPrice";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/admin-dashbord" element={<AdminDashbordPage />} />
      <Route path="/admin-stakeone" element={<AdminStakeOne />} />
      <Route path="/admin-staketwo" element={<AdminStakeTwo />} />
      <Route path="/admin-stakethree" element={<AdminStakeThree />} />
      <Route path="/admin-stakefour" element={<AdminstackFour />} />
      <Route path="/admin-staking" element={<AdminStaking />} />
      <Route path="/admin-price" element={<AdminstackPrice />} />
      <Route path="/user-dashbord" element={<UserDashbordPage />} />
      <Route path="/stack-token" element={<StackTokenPage />} />
      <Route path="/stack-token-v2" element={<StackTokenv2Page />} />
      <Route path="/stack-token-v3" element={<StackTokenv3Page />} />
      <Route path="/stack-token-v4" element={<StackTokenv4Page />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/stakeone" element={<StakeOne />} />
      <Route path="/staketwo" element={<StakeTwo />} />
      <Route path="/stakethree" element={<StakeThree />} />
      <Route path="/stakefour" element={<StakeFour />} />
      <Route path="/staking" element={<StakingPage />} />
      <Route path="/register/:id" element={<RegisterPage />} />
    </Routes>
  );
}

export default App;
