import React, { useEffect, useState } from "react";
import "../AdminPageSection/styles.css";
import { ethers } from "ethers";
import { StakeAbi, StakeAddress } from "../Abi/Content";
import Loading from "../LoaderPageSection/Loading";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AdminPageSection from "./Content";
const AdminstackOne = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getFirstYear, setGetFirstYear] = useState("");
  const [getSecondYear, setGetSecondYear] = useState("");
  const [getThirdYear, setGetThirdYear] = useState("");
  const [getAllSingleData, setGetAllSingleData] = useState({});
  const [getAllRefferalSingleData, setGetAllRefferalSingleData] = useState({});
  const [refferalFirstYear, setRefferalFirstYear] = useState("");
  const [refferalSecondYear, setRefferalSecondYear] = useState("");
  const [refferalThirdYear, setRefferalThirdYear] = useState("");
  const [percentage, setPercentage] = useState("");
  const [refferalPercentage, setRefferalPercentage] = useState("");
  const [isToggled, setIsToggled] = useState(0);
  let getLocalData = localStorage.getItem("auth");
  getLocalData = JSON.parse(getLocalData);

  const allYearData = [
    {
      id: "1",
      year: "1 Year",
      day: 365,
      percentage: getFirstYear,
    },
    {
      id: "2",
      year: "2 Year",
      day: 730,
      percentage: getSecondYear,
    },
    {
      id: "3",
      year: "3 Year",
      day: 1095,
      percentage: getThirdYear,
    },
  ];

  const RefferalData = [
    {
      id: "1",
      year: "1 Year",
      day: 365,
      percentage: refferalFirstYear,
    },
    {
      id: "2",
      year: "2 Year",
      day: 730,
      percentage: refferalSecondYear,
    },
    {
      id: "3",
      year: "3 Year",
      day: 1095,
      percentage: refferalThirdYear,
    },
  ];

  const loadData = async () => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let getFirstYearPercentage = await StakeContract.getBonusFromDays(365);
      getFirstYearPercentage = parseFloat(getFirstYearPercentage) / 10;
      setGetFirstYear(getFirstYearPercentage);

      let getSecondYearPercentage = await StakeContract.getBonusFromDays(730);
      getSecondYearPercentage = parseFloat(getSecondYearPercentage) / 10;
      setGetSecondYear(getSecondYearPercentage);

      let getThirdYearPercentage = await StakeContract.getBonusFromDays(1095);
      getThirdYearPercentage = parseFloat(getThirdYearPercentage) / 10;
      setGetThirdYear(getThirdYearPercentage);

      // refferal year percentage

      let getFirstYearRefferalPercentage =
        await StakeContract.getReferalBonusFromDays(365);
      getFirstYearRefferalPercentage =
        parseFloat(getFirstYearRefferalPercentage) / 10;
      setRefferalFirstYear(getFirstYearRefferalPercentage);

      let getSecondYearRefferalPercentage =
        await StakeContract.getReferalBonusFromDays(730);
      getSecondYearRefferalPercentage =
        parseFloat(getSecondYearRefferalPercentage) / 10;
      setRefferalSecondYear(getSecondYearRefferalPercentage);

      let getThirdYearRefferalPercentage =
        await StakeContract.getReferalBonusFromDays(1095);
      getThirdYearRefferalPercentage =
        parseFloat(getThirdYearRefferalPercentage) / 10;
      setRefferalThirdYear(getThirdYearRefferalPercentage);
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleUpdateYearPercentage = async (days, percentage) => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let tx = await StakeContract.setBounsPercentage(days, percentage * 10);
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload(false);
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  const handleUpdateRefferalPercentage = async (days, refpercentage) => {
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      const StakeContract = new ethers.Contract(StakeAddress, StakeAbi, signer);
      let tx = await StakeContract.setReferalPercentageFromDays(
        days,
        refpercentage * 10
      );
      const receipt = await tx.wait();
      if (receipt.status !== 1) {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.success("Update Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location.reload(false);
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    async function handleData() {
      await loadData();
    }
    handleData();
  }, []);

  useEffect(() => {
    if (getLocalData === null) {
      navigate("/");
    }
  }, [getLocalData]);

  useEffect(() => {
    const myToggled = localStorage.getItem("myToggled");
    if (myToggled === null || myToggled === undefined) {
      setIsToggled(0);
    } else {
      setIsToggled(myToggled);
    }
    console.log("myToggled", myToggled);
  }, [isToggled]);
  return (
    <div>
      <AdminPageSection>
        {loading && <Loading loading={loading} />}
        <div className="home-bg">
          <div className="container">
            <div className="row my-5">
              <div className="col-12">
                <div className="adminbox">
                  <div className="row">
                    <div className="col-12">
                      <div>
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li
                            className="nav-item cus-navlink"
                            role="presentation"
                          >
                            <button
                              className={`nav-link w-100  background-active-bg cus-buttontex ${
                                isToggled === "0" && "active"
                              }`}
                              type="button"
                              onClick={() => (
                                setIsToggled(0),
                                localStorage.setItem("myToggled", 0)
                              )}
                            >
                              Year Percentage
                            </button>
                          </li>
                          <li
                            className="nav-item cus-navlink"
                            role="presentation"
                          >
                            <button
                              className={`nav-link w-100 background-active-bg cus-buttontex ${
                                isToggled === "1" && "active"
                              }`}
                              type="button"
                              onClick={() => (
                                setIsToggled(1),
                                localStorage.setItem("myToggled", 1)
                              )}
                            >
                              Referral Percentage
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-5">
              <div className="col-12">
                <div className="adminbox-caption">
                  <div className="tab-content">
                    {isToggled === "0" && (
                      <div className="tab-pane fade show active">
                        <div className="table-responsive">
                          <h5 className="text-center my-3">
                            Yearas Percentage
                          </h5>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Year</th>
                                <th scope="col">Percentage</th>
                                <th scope="col">Update</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allYearData &&
                                allYearData.map((value, key) => {
                                  return (
                                    <>
                                      <tr>
                                        <th scope="row">{key + 1}</th>
                                        <td>{value?.year}</td>
                                        <td>{value?.percentage}%</td>
                                        <td>
                                          <button
                                            className="btn connect-btn"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal1"
                                            onClick={() =>
                                              setGetAllSingleData(value)
                                            }
                                          >
                                            Edit
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>

                          {/* edit year modal */}
                          <div>
                            <div
                              className="modal fade"
                              id="exampleModal1"
                              tabIndex={1}
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog cus-modal">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel1"
                                    >
                                      Update Year
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="mb-3">
                                        <label className="form-label">
                                          year
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={getAllSingleData.year}
                                          disabled
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Percentage
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={percentage}
                                          onChange={(e) =>
                                            setPercentage(e.target.value)
                                          }
                                        />
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        handleUpdateYearPercentage(
                                          getAllSingleData.day,
                                          percentage
                                        )
                                      }
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {isToggled === "1" && (
                      <div className="tab-pane fade show active ">
                        <div className="table-responsive">
                          <h5 className="text-center my-3">
                            Referral Percentage
                          </h5>
                          <table className="table">
                            <thead>
                              <tr>
                                <th scope="col">#</th>
                                <th scope="col">Year</th>
                                <th scope="col">Percentage</th>
                                <th scope="col">Update</th>
                              </tr>
                            </thead>
                            <tbody>
                              {RefferalData &&
                                RefferalData.map((value, key) => {
                                  return (
                                    <tr>
                                      <th scope="row">{key + 1}</th>
                                      <td>{value?.year}</td>
                                      <td>{value.percentage}%</td>
                                      <td>
                                        <button
                                          className="btn connect-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                          onClick={() =>
                                            setGetAllRefferalSingleData(value)
                                          }
                                        >
                                          Edit
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                          {/* edit lavel modal */}
                          <div>
                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex={1}
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog cus-modal">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModalLabel"
                                    >
                                      Update Referral Percentage
                                    </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    />
                                  </div>
                                  <div className="modal-body">
                                    <form>
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Referral Year
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={getAllRefferalSingleData?.year}
                                          disabled
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Percentage
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          value={refferalPercentage}
                                          onChange={(e) =>
                                            setRefferalPercentage(
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </form>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      Close
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary"
                                      onClick={() =>
                                        handleUpdateRefferalPercentage(
                                          getAllRefferalSingleData?.day,
                                          refferalPercentage
                                        )
                                      }
                                    >
                                      Update
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminPageSection>
    </div>
  );
};

export default AdminstackOne;
