import { useEffect, useState } from "react";
import AdminPageSection from "./Content";
import Loading from "../LoaderPageSection/Loading";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import { useAuth } from "../../context/auth";
import {
  DeodAbi,
  DeodAddress,
  StakingAbi,
  StakingAddress,
} from "../Abi/Content";
import { BigNumber } from "bignumber.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const AdminstackPrice = () => {
  const navigate = useNavigate();
  const [auth, setAuth] = useAuth();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState("");

  const handleSetprice = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = auth?.user?.token;
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // You can adjust this as needed
      };
      const resp = await axios.post(
        `/api/setTotalStake`,
        {
          totalStakeAmount: price,
        },
        {
          headers,
        }
      );
      if (resp.status === 200) {
        if (resp?.data?.status === true) {
          toast.success(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate("/admin-price");
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const handleGetprice = async () => {
      try {
        const token = auth?.user?.token;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // You can adjust this as needed
        };
        const resp = await axios.get(`/api/totalStake`, {
          headers,
        });
        if (resp.status === 200) {
          if (resp?.data?.status === true) {
            console.log(
              "totalStakeAmount",
              resp?.data?.totalStakeAmount?.totalStakeAmount
            );
            setPrice(resp?.data?.totalStakeAmount?.totalStakeAmount);
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          var err_400 = error?.response?.data?.message;
          toast.error(err_400);
        } else if (error.response.status === 401) {
          const err_401 = error?.response?.data?.message;
          toast.error(err_401);
        } else if (error.response.status === 404) {
          const err_404 = error?.response?.data?.message;
          toast.error(err_404);
        } else if (error.response.status === 500) {
          const err_500 = error?.response?.data?.message;
          toast.error(err_500);
        } else {
          console.log("Error in Register", error);
          toast.error("something went wrong");
        }
      }
    };
    handleGetprice();
  }, []);

  return (
    <>
      <AdminPageSection>
        {loading && <Loading loading={loading} />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="allotment-box">
                <form onSubmit={handleSetprice}>
                  <div className="row">
                    <div className="col-12">
                      <h4 className="text-light float-end cus-cancelbtn">X</h4>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Set Price
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label"></label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn connect-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AdminPageSection>
    </>
  );
};
export default AdminstackPrice;
