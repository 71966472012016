import React, { useEffect, useState } from "react";
import "./styles.css";
import Layout from "../Layout/Content";
import Logo from "../../../image/Logo.png";
import RegisterImg from "../../../image/register-img.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { useAuth } from "../../context/auth";
import { toast } from "react-toastify";
import axios from "axios";
import {
  DeodAbi,
  DeodAddress,
  StakeAbi,
  StakeAddress,
  StakeAddressTwo,
  StakeV2Balance,
} from "../Abi/Content";

// NEW

const Content = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useAuth();
  const [refferal, setRefferal] = useState("");
  const location = useLocation();
  const [totalDeodStack, setTotaldeodStack] = useState();
  const handleIsRegisterApi = async (address) => {
    try {
      const resp = await axios.post(`/api/user/checkAddress`, {
        accountAddress: address,
      });
      if (resp.status === 200) {
        if (resp?.data?.status === false) {
          toast.error(resp?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (resp?.data?.status === true) {
          const isAdmin = [
            "0x2aa5f670f789c7630ffddc80c6efecb2f06128ff",
            // "0x65c0cb0e58d0a45d294bc0d1c37ee8c714e1372d",
            "0xbe99181bcab37bc57d3d2c7156bb7e1b72aafba2"
          ];
          var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
          setAuth({
            ...auth,
            user: {
              address: address,
              referralAddress: refferal,
              role: role,
            },
          });
          localStorage.setItem(
            "auth",
            JSON.stringify({
              user: {
                address: address,
                referralAddress: refferal,
                role: role,
              },
            })
          );
          navigate("/register");
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        var err_400 = error?.response?.data?.message;
        toast.error(err_400);
      } else if (error.response.status === 401) {
        const err_401 = error?.response?.data?.message;
        toast.error(err_401);
      } else if (error.response.status === 404) {
        const err_404 = error?.response?.data?.message;
        toast.error(err_404);
      } else if (error.response.status === 500) {
        const err_500 = error?.response?.data?.message;
        toast.error(err_500);
      } else {
        console.log("Error in Register", error);
        toast.error("something went wrong");
      }
    }
  };

  const handleIsregister = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window.ethereum) {
        provider = window.ethereum;
      } else if (window.web3) {
        provider = window.web3.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const newProvider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = newProvider.getSigner();
      const message = [
        "Welcome to the Decentrawood Lock!",
        "Please sign this message to verify your identity.",
        "Please sign in!",
      ].join("\n\n");
      await signer.signMessage(message);
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();
      await handleIsRegisterApi(address);
    } catch (error) {
      console.log("wallet loagin Error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (location?.search !== "") {
      var dataSplit = location?.search.split("/");
      dataSplit = dataSplit[0].split("?")[1];
      setRefferal(dataSplit);
    }
  }, [location?.pathname]);

  useEffect(() => {
    // const jsonRpcUrl = "https://polygon-rpc.com";
    // const provider = new ethers.providers.JsonRpcProvider(jsonRpcUrl);

    // const handleGetTotalDeodStack = async () => {
    //   try {
    //     const DeodContract = new ethers.Contract(
    //       DeodAddress,
    //       DeodAbi,
    //       provider
    //     );
    //     let totalstackBalance = await DeodContract.balanceOf(StakeV2Balance);
    //     totalstackBalance = parseFloat(totalstackBalance) / 10 ** 18;
    //     totalstackBalance = totalstackBalance.toFixed(2);
    //     setTotaldeodStack(totalstackBalance);
    //   } catch (error) {
    //     console.log("totalstack error", error);
    //   }
    // };

    const handleGetTotalDeodStack = async () => {
      try {
        const token = auth?.user?.token;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // You can adjust this as needed
        };
        const resp = await axios.get(`/api/totalStake`, {
          headers,
        });
        if (resp.status === 200) {
          if (resp?.data?.status === true) {
            console.log(
              "totalStakeAmount",
              resp?.data?.totalStakeAmount?.totalStakeAmount
            );
            setTotaldeodStack(resp?.data?.totalStakeAmount?.totalStakeAmount);
          }
        } else {
          toast.error("Error", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        if (error.response.status === 400) {
          var err_400 = error?.response?.data?.message;
          toast.error(err_400);
        } else if (error.response.status === 401) {
          const err_401 = error?.response?.data?.message;
          toast.error(err_401);
        } else if (error.response.status === 404) {
          const err_404 = error?.response?.data?.message;
          toast.error(err_404);
        } else if (error.response.status === 500) {
          const err_500 = error?.response?.data?.message;
          toast.error(err_500);
        } else {
          console.log("Error in Register", error);
          toast.error("something went wrong");
        }
      }
    };

    handleGetTotalDeodStack();
  }, []);

  return (
    <Layout>
      <div className="home-bg">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center mt-5">
              <div className="deod-balance">
                <div className="row">
                  <div className="col-3 walled-deod-iconclr">
                    <div className="deod-walled-icon">
                      <i class="bi bi-wallet2"></i>
                    </div>
                  </div>
                  <div className="col-9">
                    <p>Total deod amount staked</p>
                    <p> {totalDeodStack}</p>
                  </div>
                </div>
              </div>
              <div className="register-box">
                <h2 className="text-white">
                  Decentrawood <span className="cus-span">Stake</span>
                </h2>
                <div className="row">
                  <div className="col-12 col-md-12">
                    <div className="register-img">
                      <img
                        src={RegisterImg}
                        className="img-fluid"
                        alt="register"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-12">
                    {/* <div className="rglogo-box">
                      <img src={Logo} alt="rglogo" />
                    </div> */}
                    <form onSubmit={handleIsregister}>
                      <button className="register-btn" type="submit">
                        Register
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Content;
