import { useState } from "react";
import AdminPageSection from "./Content";
import Loading from "../LoaderPageSection/Loading";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import {
  DeodAbi,
  DeodAddress,
  StakingAbi,
  StakingAddress,
} from "../Abi/Content";
import { BigNumber } from "bignumber.js";
const AdminStaking = () => {
  const [loading, setLoading] = useState(false);
  const [reciveAddress, setReciveAddress] = useState("");
  const [days, setDays] = useState("");
  const [amount, setAmount] = useState("");

  const handleStaking = async (e) => {
    debugger;
    e.preventDefault();
    setLoading(true);
    try {
      let provider;
      if (window?.ethereum) {
        provider = window?.ethereum;
      } else if (window?.web3) {
        provider = window?.web3?.currentProvider;
      } else {
        window.alert("No ethereum browser !checkout metamask");
      }
      if (provider) {
        if (provider !== window?.ethereum) {
          console.error(
            "Not window.ethereum.Do you have multiple wallets installed"
          );
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      const oldProvider = new ethers.providers.Web3Provider(window.ethereum);
      const network = await oldProvider.getNetwork();
      // console.log( network)
      // if (network.chainId !== networkId) {
      //   await switchNetworks();
      // }
      const newProvider = new ethers.providers.Web3Provider(window?.ethereum);
      const signer = newProvider.getSigner();
      const currentaccount = await signer.getAddress();
      const address = currentaccount.toLowerCase();

      const isAdmin = [
        "0x2aa5f670f789c7630ffddc80c6efecb2f06128ff",
        // "0x65c0cb0e58d0a45d294bc0d1c37ee8c714e1372d",
        "0xbe99181bcab37bc57d3d2c7156bb7e1b72aafba2"
      ];
      var role = isAdmin.indexOf(address) !== -1 ? 1 : 0;
      if (role === 1) {
        const DeodContract = new ethers.Contract(DeodAddress, DeodAbi, signer);
        const StakingContract = new ethers.Contract(
          StakingAddress,
          StakingAbi,
          signer
        );
        let x;
        x = amount * 10 ** 18;
        let z = new BigNumber(x);
        let a = z.toFixed();

        let yAmount;
        yAmount = (+amount + 1) * 10 ** 18;
        let zAmount = new BigNumber(yAmount);
        let aproveAmount = zAmount.toFixed();

        const txn = await DeodContract.approve(StakingAddress, aproveAmount);
        const receipttxn = await txn.wait();
        if (receipttxn.status !== 1) {
          alert("error message");
        } else {
          const txn1 = await StakingContract.stakeTokenToUser(
            reciveAddress,
            a,
            days
          );
          const receipttxn1 = await txn1.wait();
          if (receipttxn1.status !== 1) {
            alert("error message");
          } else {
            toast.success(`staking successfully`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      } else {
        toast.error(`connect correct wallet`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.log("loadBlockchainData", error);
    }
    setLoading(false);
  };
  return (
    <>
      <AdminPageSection>
        {loading && <Loading loading={loading} />}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="allotment-box">
                <form onSubmit={handleStaking}>
                  {/* <form> */}
                  <div className="row">
                    <div className="col-12">
                      <h4
                        className="text-light float-end cus-cancelbtn"
                        // onClick={() => navigate("/stakethree")}
                      >
                        X
                      </h4>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">
                          Wallet Address
                        </label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={reciveAddress}
                          onChange={(e) => setReciveAddress(e.target.value)}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">Days</label>
                        <input
                          type="text"
                          className="form-control cus-control-form"
                          value={days}
                          onChange={(e) => setDays(e.target.value)}
                          required
                        />
                        {/* <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => setDays(e.target.value)}
                        >
                          <option value="360">12 Month</option>
                          <option value="720">24 Month</option>
                          <option value="1080">24 Month</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label">Amount</label>
                        <input
                          type="number"
                          className="form-control cus-control-form"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          required
                        />
                        {/* <span className="my-3 mx-2 text-warning font-weight-bold ">
                          Amount should be greater then 1000
                        </span> */}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label cus-label"></label>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn connect-btn">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </AdminPageSection>
    </>
  );
};
export default AdminStaking;
