export const dummyDatas=[
    {
        "walletAddress":"0x0662088969c3e831dd973c2efca63603e4e90877",
        "referralEarning":57714.84,
        "availableWithdrawal":0,
        "totalWithdrawal":51677.44,
    },
    {
        "walletAddress":"0x8ca1a7a63427ac9685d876e5e4f6a82f214003e7",
        "referralEarning":0,
        "availableWithdrawal":0,
        "totalWithdrawal":440,
    },
    {
        "walletAddress":"0x0f0e166426cff4b54feb295ae1c4ba2f4f2509cc",
        "referralEarning":0,
        "availableWithdrawal":0,
        "totalWithdrawal":880,
    },
    // {
    //     "walletAddress":"0xab40155eadeba5223cd26d2dca9fb8a4a3393fa9",
    //     "referralEarning":10,
    //     "availableWithdrawal":20,
    //     "totalWithdrawal":880,
    // }
];

