import React from "react";
import Layout from "../Layout/Content";
import { Link, useNavigate } from "react-router-dom";
const Content = ({ children }) => {
  const navigate = useNavigate();
  return (
    <>
      <Layout title={"Landing page"}>
        <div className="poke-bg">
          <div className="container py-4">
            <nav className="navbar bg-offcanwas-nav border-none shadow-none">
              <div className="container">
                <a
                  className="navbar-brand text-light"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasExample"
                  role="button"
                  aria-controls="offcanvasExample"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="#fff"
                    class="bi bi-list"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </a>
              </div>
            </nav>
          </div>
          {/* offcanwas */}

          <div
            class="offcanvas offcanvas-start offcanvas-start-cus"
            tabindex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                Staking
              </h5>
              <button
                type="button"
                class="btn shadow-none"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#fff"
                  class="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </button>
            </div>
            <div className="offcanwas-body">
              <div className="sidebar-link">
                <ul className="side-ul">
                  <li
                    className="dash-active"
                    onClick={() => navigate("/admin-stakeone")}
                  >
                    <Link>
                      {" "}
                      <i class="bi bi-speedometer2"></i>Dashboard
                    </Link>
                  </li>
                  <li onClick={() => navigate("/admin-stakeone")}>
                    <Link to={"/stakeone"}>
                      <i class="bi bi-stack"></i> Admin Stake 1
                    </Link>
                  </li>
                  <li onClick={() => navigate("/admin-staketwo")}>
                    <Link>
                      {" "}
                      <i class="bi bi-stack"></i> Admin Stake 2
                    </Link>
                  </li>
                  <li onClick={() => navigate("/admin-stakethree")}>
                    <Link>
                      {" "}
                      <i class="bi bi-stack"></i> Admin Stake 3
                    </Link>
                  </li>
                  <li onClick={() => navigate("/admin-stakefour")}>
                    <Link>
                      {" "}
                      <i class="bi bi-stack"></i> Admin Stake 4
                    </Link>
                  </li>
                  <li onClick={() => navigate("/admin-staking")}>
                    <Link>
                      {" "}
                      <i class="bi bi-stack"></i> Admin Staking
                    </Link>
                  </li>
                  <li onClick={() => navigate("/admin-price")}>
                    <Link>
                      {" "}
                      <i class="bi bi-stack"></i>Price
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* end-canwas */}
          {children}
        </div>
      </Layout>
    </>
  );
};

export default Content;
